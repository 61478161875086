function guid() {

    function s4() {

        return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);

    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();

}

var activeContent;
var Components = [];

function Component(component) {

    this.id = guid();
    this.elementClass = component.elementClass;
    this.contentType = component.contentType;
    this.elementStyle = component.elementStyle;
    this.content = component.placeHolder;

    var element = $('<div></div>');
    element.addClass(this.elementClass + ' component');
    element.css(this.elementStyle);
    element.attr('id', 'component-' + this.contentType + '-' + this.id);

    if (this.contentType !== 'price_table' && this.contentType.indexOf('division') < 0) {
        var childContent = $('<div></div>');
        childContent.addClass('content');
        childContent.html(this.content);
        element.append(childContent);
    } else {
        element.html(this.content);
    }
    this.jq = element;

    this.setContent = function (content) {
        this.content = content;
    };

}


var ComponentSidebar = {
    components: {
        header: {
            elementClass: 'header',
            contentType: 'header',
            elementStyle: {
                fontSize: 30,
                fontFamily: 'Roboto Slab'
            },
            placeHolder: 'Header text'
        },
        text_block: {
            elementClass: 'text-block',
            contentType: 'text-block',
            elementStyle: {
                fontSize: 16
            },
            placeHolder: 'Text block text'

        },
        picture: {
            elementClass: 'picture',
            contentType: 'picture',
            elementStyle: {
                textAlign: 'center'
            },
            placeHolder: '<img data-unit="px" class="content" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Test&w=350&h=150" alt="">'
        },
        price_table: {
            elementClass: 'price_table',
            contentType: 'price_table',
            elementStyle: {},
            placeHolder: function () {
                return ComponentSidebar.createPriceTable();
            }
        },
        division_1_2: {
            elementClass: 'division_1_2 col-xs-6 division',
            contentType: 'division_1_2',
            elementStyle: {
                minHeight: '40px'
            },
            placeHolder: function () {

            }
        },
        division_1_3: {
            elementClass: 'division_1_3 col-xs-4 division',
            contentType: 'division_1_3',
            elementStyle: {
                minHeight: '40px'
            },
            placeHolder: function () {

            }
        },
        division_1_4: {
            elementClass: 'division_1_4 col-xs-3 division',
            contentType: 'division_1_4',
            elementStyle: {
                minHeight: '40px'
            },
            placeHolder: function () {

            }
        }
    },
    init: function () {

        $('.components-toolbar .list-group-item').on('dragstart', function (e) {
            e.originalEvent.dataTransfer.setData("contentType", $(this).data('component'));
        });

        $(".components-toolbar").draggable({
            handle: '.panel-move-handle'
        });

    },
    createDivision: function () {
//        var divCol = $('<div></div>');
//        divCol.addClass('col-xs-6');
//        divCol.css({
//            'min-height': '40px'
//        });
//        
//        return divCol;
    },
    createPriceTable: function () {
        var table = $('<table></table>');
        table.addClass('table table-striped');

        var tbody = $('<tbody></tbody>');
        tbody.addClass('content');

        var thead = $('<thead></thead>');
        var theadTr = $('<tr></tr>');
        var theadElements = [
            'Megnevezés',
            'Mennyiségi egység',
            'Egységár',
            'Mennyiség',
            'Összesen'
        ];

        $.each(theadElements, function (index, value) {
            var th = $('<th></th>');
            th.text(value.trim());
            theadTr.append(th);
        });

        thead.append(theadTr);
        table.append(thead);
        table.append(this.createPriceTableFooter());
        table.append(tbody);

        return table;

    },
    createPriceTableRow: function (elements) {
        var tr = $('<tr></tr>');
        var trElements = elements;

        $.each(trElements, function (index, value) {
            var td = $('<td></td>');

            if (index === 4) {
                td.addClass('price-table-summ warning');
            }

            td.text(value.trim());
            tr.append(td);
        });
        var td = $('<td><button type="button" class="close"><span>&times;</span></button></td>');
        tr.append(td);

        return tr;
    },
    createPriceTableFooter: function () {
        var tfoot = $('<tfoot></tfoot>');
        var tfootTr = $('<tr></tr>');
        tfootTr.addClass('info');
        var theadElements = [
            '',
            '',
            '',
            '',
            '<span class="price-table-all-summ">0</span>'
        ];

        $.each(theadElements, function (index, value) {
            var td = $('<td></td>');
            if (index === 4) {
                td.html(value);
            }
            tfootTr.append(td);
        });

        tfoot.append(tfootTr);
        return tfoot;
    }
};


var EditedArea = {
    init: function () {
        this.drop();
        this.sort();
    },
    sort: function () {
    },
    drop: function () {
        $('#edited-area').on('drop', function (e) {

            e.preventDefault();

            var type = e.originalEvent.dataTransfer.getData("contentType");
            type = type.replace(/-/g, '_');
            var createdComponent = new Component(ComponentSidebar.components[type]);

            activeContent = createdComponent.jq;

            Components.push(createdComponent);
            if ($(e.target).hasClass('edited-area') || $(e.target).hasClass('division')) {
                $(e.target).append(createdComponent.jq);
                $(FormatToolbar.getEditWindowID()).modal('show');
            }

        }).on('dragover', function (e) {

            e.preventDefault();

        });
    }

};


var FormatToolbar = {
    init: function () {
        this.attachToActive();
        this.removeComponent();
        this.alignText();
        this.moveComponent();
    },
    attachToActive: function () {
        $(document).on('click', 'body', function (e) {

            var component;

            if ($(e.target).hasClass('component')) {
                component = $(e.target);
            } else if ($(e.target).closest('.component').length > 0) {
                component = $(e.target).closest('.component');
            }

            if (component) {

                activeContent = component;

                $('#edit-component').attr('data-target', FormatToolbar.getEditWindowID());

                activeContent.append($('.format-toolbar'));
                TextSettingsWindow.loadData();
            }

        });
    },
    getEditWindowID: function () {
        if (activeContent.hasClass('price_table')) {
            return '#price-table-settings-window';
        } else if (activeContent.hasClass('header') || activeContent.hasClass('text-block')) {
            return '#text-settings-window';
        } else if (activeContent.hasClass('picture')) {
            return '#picture-settings-window';
        }
    },
    moveComponent: function () {
        $('#edited-area, .division').sortable({
            handle: "#move-component-handle",
            cancel: ".content"
//            items: ">.component"
        });
        $('#edited-area').disableSelection();
    },
    removeComponent: function () {
        $(document).on('click', 'button.remove-component', function (e) {
            e.preventDefault();
            $('body').append($(this).parent().clone());
            $(this).closest('.component').remove();
        });
    },
    alignText: function () {
        $(document).on('click', 'button.align-left-component', function (e) {
            e.preventDefault();
            activeContent.css({
                textAlign: 'left'
            });
        });
        $(document).on('click', 'button.align-right-component', function (e) {
            e.preventDefault();
            activeContent.css({
                textAlign: 'right'
            });
        });
        $(document).on('click', 'button.align-center-component', function (e) {
            e.preventDefault();
            activeContent.css({
                textAlign: 'center'
            });
        });
        $(document).on('click', 'button.align-justify-component', function (e) {
            e.preventDefault();
            activeContent.css({
                textAlign: 'justify'
            });
        });
    }
};


var TextSettingsWindow = {
    init: function () {
        this.saveData();

        $('#text-settings-window').on('shown.bs.modal', function () {
            TextSettingsWindow.loadData();
        });
    },
    saveData: function () {
        $(document).on('click', 'button.save-text-settings', function (e) {
            e.preventDefault();
            activeContent.find('.content').css({
                fontSize: $('#set-text-font-size').find(":selected").text() + 'px',
                fontFamily: $('#set-text-font-family').find(":selected").text()
            });

            activeContent.find('.content').text($('#set-text-content').val().trim());

            $('#text-settings-window').modal('hide');
        });
    },
    loadData: function () {
        $('#set-text-font-size option').filter(function () {
            return parseInt($(this).text()) === parseInt(activeContent.find('.content').css('font-size'));
        }).prop('selected', true);

        $('#set-text-font-family option').filter(function () {
            return '\'' + $(this).text() + '\'' === activeContent.find('.content').css('font-family');
        }).prop('selected', true);

        $('#set-text-content').val(activeContent.find('.content').text().trim());
    }
};


var PriceTableSettingsWindow = {
    init: function () {
        this.updateData();
        this.calculate();

        $('#price-table-settings-window').on('shown.bs.modal', function () {
            PriceTableSettingsWindow.loadData();
        });
    },
    calculate: function () {
        $('#set-price-unit-price, #set-price-quantity').on('keyup', function () {
            var unitPrice = parseInt($('#set-price-unit-price').val());
            var quantity = parseInt($('#set-price-quantity').val());
            var result = unitPrice * quantity;
            if (isNaN(result)) {
                var warnSign = $('<span></span>');
                warnSign.addClass('glyphicon glyphicon-warning-sign form-control-feedback');

                if (!$('#set-price-all').parent().hasClass('has-warning')) {
                    $('#set-price-all').parent().addClass('has-warning');
                    $('#set-price-all').parent().find('.form-control-feedback').addClass('glyphicon glyphicon-warning-sign');
                    $('#set-price-all').val('Érvénytelen adatok');
                }
            } else {
                $('#set-price-all').parent().removeClass('has-warning');
                $('#set-price-all').parent().find('.form-control-feedback').removeClass('glyphicon glyphicon-warning-sign');
                $('#set-price-all').val(result);
            }

        });
    },
    updateData: function () {
        $(document).on('click', '#price-table-current-content button.close', function (e) {
            var index = $(this).closest('tr').index();
            var currentContent = activeContent.find('tbody.content');
            var activeContentRow = $(currentContent.find('tr').get(index));
            activeContentRow.remove();
            $(this).closest('tr').remove();

            var summ = 0;
            activeContent.find('.price-table-summ').each(function () {
                var value = parseInt($(this).text());
                summ += value;
            });

            activeContent.find('.price-table-all-summ').text(summ);

        });
        $(document).on('click', 'button.add-new-row', function (e) {
            e.preventDefault();

            var values = [
                $('#set-price-name').val(),
                $('#set-price-unit').val(),
                $('#set-price-unit-price').val(),
                $('#set-price-quantity').val(),
                $('#set-price-all').val()
            ];

            var tableRow = ComponentSidebar.createPriceTableRow(values);

            //validation
            var valid = true;
            $.each(values, function (index, value) {
                if (value.trim() === '') {
                    valid = false;
                }
            });

            if (isNaN(parseInt($('#set-price-quantity').val())) || isNaN(parseInt($('#set-price-unit-price').val()))) {
                valid = false;
            }

            if (valid) {
                activeContent.find('tbody.content').append(tableRow);
                $('#set-price-name, #set-price-unit, #set-price-unit-price, #set-price-quantity, #set-price-all').val(null);
                PriceTableSettingsWindow.loadData();
            }

            var summ = 0;
            activeContent.find('.price-table-summ').each(function () {
                var value = parseInt($(this).text());
                summ += value;
            });

            activeContent.find('.price-table-all-summ').text(summ);

        });
    },
    loadData: function () {
        var tableContent = activeContent.find('tbody.content').html();
        $('#price-table-current-content').html(tableContent);
    }
};


var PictureSettingsWindow = {
    init: function () {
        this.saveData();

        $('#picture-settings-window').on('shown.bs.modal', function () {
            PictureSettingsWindow.loadData();
        });
    },
    saveData: function () {
        $(document).on('click', 'button.set-picture', function (e) {
            e.preventDefault();
            var img = activeContent.find('img.content');
            img.attr('src', $('#set-picture-url').val());

            var unit = $('input[name="set-picture-width-unit"]:checked').val();

            if (unit === 'percent') {
                unit = '%';
            }

            img.width($('#set-picture-width').val() + unit);
            img.attr('data-unit', $('input[name="set-picture-width-unit"]:checked').val());

            $('#picture-settings-window').modal('hide');
        });
    },
    loadData: function () {
        var img = activeContent.find('img.content');
        var currentUrl = img.attr('src');
        $('#set-picture-url').val(currentUrl);

        var width = img.width();

        if (img.attr('data-unit') === 'percent') {
            $('#set-picture-width-unit-0').prop('checked', true);
            width = img.width() / img.parent().width() * 100;
        } else if (img.attr('data-unit') === 'px') {
            $('#set-picture-width-unit-1').prop('checked', true);
        }

        width = parseInt(width);

        $('#set-picture-width').val(width);
    }
};

$(document).ready(function () {
    ComponentSidebar.init();
    EditedArea.init();
    FormatToolbar.init();
    TextSettingsWindow.init();
    PriceTableSettingsWindow.init();
    PictureSettingsWindow.init();

    $('#set-to-pdf').on('click', function () {
        $('#thecontent').val($('#edited-area').html());
    });

});

//$('input[name="set-picture-width-unit"]').on('change', function(e){
//    console.log($(this).val());
//});